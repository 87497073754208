<template>
  <div>
    <vue-element-loading :active="appLoading" :is-full-screen="false" />
    <v-snackbar v-model="showSnackBar" color="#00a972" right :timeout="timeout">
      {{msg}}
      <v-btn text @click="showSnackBar = false">
        <v-icon style="color:white;">mdi-close</v-icon>
      </v-btn>
    </v-snackbar>
    <v-layout pa-4 wrap justify-center>
      <v-flex text-center>
        <span style="font-size:20px;font-weight:500">{{name}}</span>
      </v-flex>
    </v-layout>
    <v-layout wrap>
      <v-flex pa-4>
        <v-card width="900" min-height="65vh">
          <v-layout pa-4 wrap justify-center>
            <v-flex pl-8>
              <span style="font-size:20px;font-weight:400">Progress to date</span>
            </v-flex>
            <v-flex v-if="!items.length<1" xs12 sm6 md6 text-right>
              <v-btn text small :ripple="false" dark color="green" @click="editItem(itemId)">Edit</v-btn>
              <v-btn text small :ripple="false" dark color="red" @click="deleteItem(itemId)">Delete</v-btn>
            </v-flex>
          </v-layout>
          <v-layout v-if="!items.length<1" px-2 pb-8 pl-md-12 wrap>
            <v-flex xs12 sm6 md4 id="month_container">
              <v-list>
                <v-list-item-group v-model="item" color="#00A972">
                  <v-layout v-for="(item, i) in items" :key="i">
                    <v-flex text-uppercase>
                      <v-list-item @click="loadDescription(item)">
                        <v-list-item-content>
                          <span style="font-size:14px;font-weight:400">{{item.ProgressDate}}</span>
                        </v-list-item-content>
                      </v-list-item>
                      <v-divider></v-divider>
                    </v-flex>
                  </v-layout>
                </v-list-item-group>
              </v-list>
            </v-flex>
            <v-divider class="mx-auto" inset vertical></v-divider>
            <v-flex xs12 sm6 md7 >
              <v-layout wrap pl-4>
                <v-flex xs12 sm6 md6 text-uppercase>
                  <span style="font-size:14px;font-weight:400;color:#848484;">{{month}}</span>
                </v-flex>
              </v-layout>
              <v-layout pa-4>
                <v-flex text-justify id="month_desc_container">
                  <span style="font-size:14px;">{{description}}</span>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
          <v-layout v-else wrap justify-center>
            <v-flex text-center>
              <span style="font-size:20px;font-weight:400">No Progress</span>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>
    <v-layout v-if="addProgress" wrap justify-center>
      <v-flex>
        <v-card>
          <v-layout pa-2 px-2 wrap justify-center>
            <v-flex xs12 sm12 md3>
              <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                :return-value.sync="newProgressDate"
                transition="scale-transition"
                color="#26af82"
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="newProgressDate"
                    color="#26af82"
                    label="Select Month"
                    readonly
                    height="50"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  color="#26af82"
                  type="month"
                  v-model="newProgressDate"
                  no-title
                  scrollable
                >
                  <v-spacer></v-spacer>
                  <v-btn text color="#26af82" @click="menu = false">Cancel</v-btn>
                  <v-btn text color="#26af82" @click="$refs.menu.save(newProgressDate)">OK</v-btn>
                </v-date-picker>
              </v-menu>
            </v-flex>
          </v-layout>
          <v-layout px-2 wrap justify-center>
            <v-flex xs12 sm12 md6>
              <v-textarea label="Description" color="#26af82" rows="10" v-model="newDescription"></v-textarea>
            </v-flex>
          </v-layout>
          <v-layout pb-4 px-2 wrap justify-center>
            <v-flex xs12 sm12 md6 text-center>
              <v-btn small :ripple="false" width="220" @click="addItem" dark color="#26af82">Submit</v-btn>
            </v-flex>
            <v-flex xs12 sm12 md6 text-center>
              <v-btn small :ripple="false" width="220" @click="cancelInput" dark color="red">Cancel</v-btn>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>
    <v-layout wrap justify-center>
      <v-flex py-4 xs12 sm12 md12 text-center>
        <v-btn
          v-if="!addProgress"
          small
          :ripple="false"
          dark
          color="#26af82"
          width="200"
          @click="addProgress=true"
        >Add New</v-btn>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import VueElementLoading from "vue-element-loading";
import axios from "axios";
export default {
  props: ["name", "id"],
  components: {
    VueElementLoading
  },
  data() {
    return {
      appLoading: false,
      showSnackBar: false,
      timeout: 3000,
      msg: null,
      item: 0,
      items: [],
      month: null,
      description: null,
      itemId: null,
      menu: false,
      newProgressDate: null,
      newDescription: null,
      addProgress: false,
      progressId: null
    };
  },
  beforeMount() {
    this.getData();
  },
  methods: {
    cancelInput() {
      this.addProgress = false;
      this.progressId = null;
    },
    addItem() {
      var newUrl = null;
      this.appLoading = true;
      var dt = new Date(this.newProgressDate);
      var data = {};
      data["progressdate"] = dt;
      data["description"] = this.newDescription;
      if (this.progressId) {
        newUrl = "/Progress/edit";
        data["id"] = this.progressId;
      } else {
        newUrl = "/Progress/add";
        data["projectid"] = this.id;
      }
      axios({
        method: "POST",
        url: newUrl,
        headers: {
          "x-auth-token": localStorage.getItem("token")
        },
        data: data
      })
        .then(response => {
          this.appLoading = false;
          this.newProgressDate = null;
          this.newDescription = null;
          this.addProgress = false;
          this.progressId = null;
          this.msg = response.data.msg;
          this.showSnackBar = true;
          this.getData();
        })
        .catch(err => {
          this.appLoading = false;
          this.msg = err;
          this.msg = "Server Unreachable";
          this.showSnackBar = true;
        });
    },
    loadDescription(item) {
      this.month = item.ProgressDate;
      this.description = item.description;
      this.itemId = item._id;
    },
    getData() {
      this.appLoading = true;
      var data = {};
      data["projectid"] = this.id;
      axios({
        method: "GET",
        url: "/Progress/getlist",
        headers: {
          "x-auth-token": localStorage.getItem("token")
        },
        params: data
      })
        .then(response => {
          this.appLoading = false;
          this.items = response.data.data;
          const months = [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December"
          ];
          for (var i = 0; i < this.items.length; i++) {
            var dt = new Date(this.items[i].ProgressDate);
            var mth = months[dt.getMonth()];

            var yr = dt.getFullYear();
            var newdate = mth + " " + yr;
            this.items[i].ProgressDate = newdate;
            this.month = this.items[0].ProgressDate;
            this.description = this.items[0].description;
            this.itemId = this.items[0]._id;
          }
        })
        .catch(err => {
          this.appLoading = false;
          this.msg = err;
          this.msg = "Server Unreachable";
          this.showSnackBar = true;
        });
    },
    editItem(item) {
      const months = [
        "01",
        "02",
        "03",
        "04",
        "05",
        "06",
        "07",
        "08",
        "09",
        "10",
        "11",
        "12"
      ];
      var dt = new Date(this.month);
      var mth = months[dt.getMonth()];
      var yr = dt.getFullYear();
      var newmonth = yr + "-" + mth;
      this.newProgressDate = newmonth;
      this.newDescription = this.description;
      this.progressId = item;
      this.addProgress = true;
    },
    deleteItem(item) {
      this.appLoading = true;
      var data = {};
      data["id"] = item;
      axios({
        method: "POST",
        url: "/Progress/remove",
        headers: {
          "x-auth-token": localStorage.getItem("token")
        },
        data: data
      })
        .then(response => {
          this.appLoading = false;
          this.msg = response.data.msg;
          this.showSnackBar = true;
          this.getData();
        })
        .catch(err => {
          this.appLoading = false;
          this.msg = err;
          this.msg = "Server Unreachable";
          this.showSnackBar = true;
        });
    }
  }
};
</script>
<style scoped>
#month_container {
  max-height: 300px;
  overflow-y: auto;
}
#month_desc_container {
  max-height: 260px;
  overflow-y: auto;
}
/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px grey;
  border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #919191;
  border-radius: 5px;
}
</style>